// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H4 from "../../../../styleguide/components/Heading/H4.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as BlogPostsSidebarBlogPost from "./components/BlogPostsSidebarBlogPost.res.js";
import * as BlogPostsSidebarSectionScss from "./BlogPostsSidebarSection.scss";

var css = BlogPostsSidebarSectionScss;

function BlogPostsSidebarSection(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(H4.make, {
                            children: props.title
                          }),
                      className: css.title
                    }),
                JsxRuntime.jsx("div", {
                      children: Belt_Array.mapWithIndex(props.blogPosts, (function (index, blogPost) {
                              return JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsx(BlogPostsSidebarBlogPost.make, {
                                                blogPost: blogPost
                                              }),
                                          className: css.blogPost
                                        }, "blog-post--" + String(index));
                            })),
                      className: css.blogPosts
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("a", {
                            children: "View All Articles",
                            className: css.link,
                            href: props.blogPostsLink
                          }),
                      className: css.blogPostsLink
                    })
              ],
              className: css.wrapper
            });
}

var make = BlogPostsSidebarSection;

export {
  css ,
  make ,
}
/* css Not a pure module */
