// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../../../../../../styleguide/components/Heading/H2.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as BareMetalGetStartedButton from "../get-started-button/BareMetalGetStartedButton.res.js";
import * as BareMetalGetStartedScss from "./BareMetalGetStarted.scss";

var css = BareMetalGetStartedScss;

function BareMetalGetStarted(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsxs("div", {
                          children: [
                            JsxRuntime.jsx(H2.make, {
                                  className: css.title,
                                  children: props.title
                                }),
                            JsxRuntime.jsx(BareMetalGetStartedButton.make, {})
                          ],
                          className: css.bannerRow
                        }),
                    className: css.wrapperInner
                  }),
              className: css.wrapper
            });
}

var make = BareMetalGetStarted;

export {
  css ,
  make ,
}
/* css Not a pure module */
