// Generated by ReScript, PLEASE EDIT WITH CARE

import * as BlogPost from "../../../../models/BlogPost.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ResourceItem from "../../../../models/ResourceItem.res.js";
import * as BareMetalFaqs from "./components/faqs/BareMetalFaqs.res.js";
import * as Routes_BlogPost from "../../../../routes/common/Routes_BlogPost.res.js";
import * as ExclusiveServices from "../../../../styleguide/components/ExclusiveServices/ExclusiveServices.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as WhatIsBareMetalNew from "./components/what-is-bare-metal/WhatIsBareMetalNew.res.js";
import * as BareMetalGetStarted from "./components/request-estimate/BareMetalGetStarted.res.js";
import * as BareMetalHighlights from "./components/highlights/BareMetalHighlights.res.js";
import * as BareMetalIndexScss from "./BareMetalIndex.scss";
import * as ServicesSeparatorBlock from "../../ServicesSeparatorBlock.res.js";
import * as BlogPostsSidebarSection from "../../../blog-posts/common/sidebar-section/BlogPostsSidebarSection.res.js";
import * as JumbotronBareMetalIndex from "./components/jumbotron/JumbotronBareMetalIndex.res.js";
import * as ResourcesSidebarSection from "../../../resources/common/sidebar-section/ResourcesSidebarSection.res.js";
import * as BareMetalServicesOverview from "./components/services-overview/BareMetalServicesOverview.res.js";
import BareMetalHeaderDotsSvg from "./assets/bare-metal-header-dots.svg";

var css = BareMetalIndexScss;

var multicloudHeaderSvg = BareMetalHeaderDotsSvg;

function BareMetalIndex$Component(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(JumbotronBareMetalIndex.make, {}),
                            className: css.backgroundImage,
                            style: {
                              backgroundImage: "url(" + multicloudHeaderSvg + ")"
                            }
                          }),
                      className: css.jumbotronSection
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(BareMetalGetStarted.make, {
                            title: "Datacenters.com Experts Can Help Select the Bare Metal Deployment For You"
                          }),
                      className: css.requestEstimateBareMetalSection
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(BareMetalHighlights.make, {}),
                      className: css.highlightsSection
                    }),
                JsxRuntime.jsx(ServicesSeparatorBlock.make, {}),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(WhatIsBareMetalNew.make, {}),
                                JsxRuntime.jsx(BareMetalServicesOverview.make, {}),
                                JsxRuntime.jsx(BareMetalFaqs.make, {})
                              ],
                              className: css.detailsContent
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(ResourcesSidebarSection.make, {
                                      title: "Case Studies",
                                      categorySlug: "case-studies",
                                      resources: props.caseStudies
                                    }),
                                JsxRuntime.jsx(ResourcesSidebarSection.make, {
                                      title: "White Papers",
                                      categorySlug: "whitepapers",
                                      resources: props.whitePapers
                                    }),
                                JsxRuntime.jsx(BlogPostsSidebarSection.make, {
                                      blogPosts: props.blogPosts,
                                      blogPostsLink: Routes_BlogPost.category("bare-metal"),
                                      title: "Blog Posts"
                                    }),
                                JsxRuntime.jsx(ExclusiveServices.make, {
                                      title: "Exclusive Services",
                                      services: [
                                        {
                                          service: "Adjust Servers as Needed",
                                          description: "Our dynamic resource allocation system allows you to easily adjust your server capacity as your business demands change, giving you the flexibility you need to stay competitive in a rapidly evolving market. Say goodbye to fixed costs and hello to hassle-free scalability - today!"
                                        },
                                        {
                                          service: "No Cost White Glove Concierge",
                                          description: "Looking for a seamless way to manage your server infrastructure? Datacenters.com has got you covered with our free white glove concierge service. Our team of experts will help you find the best bare metal solution to suit your specific needs, saving you valuable time, resources, and money. Harness their knowledge and experience to enjoy flawless server management that works for you."
                                        },
                                        {
                                          service: "Add New Regions With Ease",
                                          description: "Maximize your global potential and take control of your business with the ability to easily add or remove servers in any region, all at just one click. Unleash never-before seen opportunities for scaling up - it's that simple!"
                                        },
                                        {
                                          service: "Simple Integration",
                                          description: "Create seamless connections between regions by leveraging affordable server solutions that effortlessly link private and public clouds. Take the headache out of platform integration and simplify your workflow with ease."
                                        },
                                        {
                                          service: "Fully Customizable",
                                          description: "Tailor-made IaaS bare metal servers are at your fingertips, ready to power your unique business needs. No more exhausting comparisons, just easy customization to get the ideal solution."
                                        },
                                        {
                                          service: "Industry Leading Solution Providers",
                                          description: "Find the perfect bare metal provider for your infrastructure by comparing key factors such as price, performance, reliability, and scalability. Make an informed decision and get the best solution for your needs."
                                        },
                                        {
                                          service: "Unlimited Scalability",
                                          description: "Unlock Your business's growth potential with greater scalability and reliability. Achieve business expansion without limitations or disruptions by having the ability to easily scale your services up or down in response to changes in demand or growth. Ensure consistent availability and functionality of your services with complete reliability."
                                        },
                                        {
                                          service: "Blazing Speeds and Low Latency",
                                          description: "Get lightning-fast processing speeds and reduced latency with the superior performance of bare metal solutions. Perfect for organizations that demand high-performance computing."
                                        },
                                        {
                                          service: "Enhanced Security",
                                          description: "Choose bare metal deployments for heightened data security in your organization's growth strategy. Enjoy benefits like dedicated hardware resources, physical security, complete control over server configuration, and isolation from other tenants. Prioritize your data security like never before."
                                        }
                                      ]
                                    })
                              ],
                              className: css.detailsSidebar
                            })
                      ],
                      className: css.detailsSection
                    })
              ],
              className: css.wrapper
            });
}

var Component = {
  make: BareMetalIndex$Component
};

function BareMetalIndex$default(props) {
  var caseStudies = Belt_Array.map(props.caseStudies, ResourceItem.Sidebar.fromJs);
  var whitePapers = Belt_Array.map(props.whitePapers, ResourceItem.Sidebar.fromJs);
  var blogPosts = Belt_Array.map(props.blogPosts, BlogPost.Sidebar.fromJs);
  return JsxRuntime.jsx(BareMetalIndex$Component, {
              caseStudies: caseStudies,
              whitePapers: whitePapers,
              blogPosts: blogPosts
            });
}

var make = BareMetalIndex$Component;

var $$default = BareMetalIndex$default;

export {
  css ,
  multicloudHeaderSvg ,
  Component ,
  make ,
  $$default as default,
}
/* css Not a pure module */
