// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../../../../../../styleguide/components/Heading/H2.res.js";
import * as Faq from "../../../../../../styleguide/components/Faq/Faq.res.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Routes_Project from "../../../../../../routes/common/Routes_Project.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as BareMetalFaqsScss from "./BareMetalFaqs.scss";
import * as ScheduleModalServices from "../../../../common/schedule-modal-services/ScheduleModalServices.res.js";

var css = BareMetalFaqsScss;

function BareMetalFaqs(props) {
  var match = React.useState(function () {
        return false;
      });
  var setIsScheduleDemoModalOpen = match[1];
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(H2.make, {
                      className: css.heading,
                      children: "Learn More About Bare Metal"
                    }),
                JsxRuntime.jsx(Faq.make, {
                      question: "What bare metal services are available?",
                      answerWithLink: Caml_option.some(JsxRuntime.jsxs("div", {
                                children: [
                                  "Our cloud platform is designed with flexibility in mind. You can mix and match dedicated servers to create your ideal deployment. This freedom of choice affords your organization the ability to maximize cost efficiency and optimize delivery flexibility across multiple regions. So go ahead, get creative and tailor a ",
                                  JsxRuntime.jsx("a", {
                                        children: "bare metal solution",
                                        className: css.answerWithLink,
                                        href: Routes_Project.BareMetal.$$new
                                      }),
                                  " that seamlessly meets the unique needs of your business!"
                                ]
                              }))
                    }),
                JsxRuntime.jsx(Faq.make, {
                      question: "What region of the world can I get bare metal IaaS?",
                      answer: "Ready to go global? Our enterprise-grade bare metal servers can help you get off the ground in over 25 countries, from the towering mountains of China to the sunbaked beaches of India and Brazil, emerging markets like Indonesia and Western Africa, down through South Africa--all custom tailored for your hosting needs. Let your production capabilities grow along with your data center requirements--we have the resources to make it happen."
                    }),
                JsxRuntime.jsx(Faq.make, {
                      question: "Why should I use bare metal IaaS with Datacenters.com?",
                      answer: "Discovering the perfect cloud computing services has never been so simple and effortless. With our cutting edge technology, anyone can intuitively create a project and connect with providers - it's a quick and easy experience! And if any questions arise along the way, you'll have full access to some of the most knowledgeable experts in the industry who'll be right there alongside you, guiding you through each step of the process with care, clarity and total convenience. And the best part? Our technology experts are available to you at absolutely no cost."
                    }),
                JsxRuntime.jsx(Faq.make, {
                      question: "How does the bare metal platform improve user experience?",
                      answer: "Our revolutionary platform makes it easier than ever for users to get virtual servers ready quickly, whatever the location. No more fielding and responding to complicated procurement requests - now users can finally be in control of where, when and how fast their deployments occur! Plus, our up-front and transparent pricing approach gives users the total cost information they need to make decisions about their projects. By taking away the hassle of traditional virtualized computing resources, we have placed power back into the hands of our users."
                    }),
                JsxRuntime.jsx(Faq.make, {
                      question: "I'm not ready to deploy. How do I get a demo first?",
                      answerWithLink: Caml_option.some(JsxRuntime.jsxs("div", {
                                children: [
                                  "Our Concierge team is available to assist you in taking the next step - all at no cost to you. All you must do is ",
                                  JsxRuntime.jsx("a", {
                                        children: "click here",
                                        className: css.answerWithLink,
                                        href: "#",
                                        onClick: (function (_event) {
                                            setIsScheduleDemoModalOpen(function (param) {
                                                  return true;
                                                });
                                          })
                                      }),
                                  " and fill out the form. Together, we can take the time to set everything up and ensure all your needs are met. Our Concierge team is always available to make sure you have a personal and seamless experience with our platform. Let us help you design a custom solution for your business and make this transition smooth!"
                                ]
                              }))
                    }),
                JsxRuntime.jsx(Faq.make, {
                      question: "IaaS vs PaaS vs SaaS?",
                      answer: "Discovering which cloud service option is best for you can be overwhelming. Fortunately, our technology experts can provide guidance on the differences between IaaS, PaaS, and SaaS. Get the insight you need to make an informed decision with our no-cost consultation."
                    }),
                JsxRuntime.jsx(Faq.make, {
                      question: "Bare Metal vs Colocation?",
                      answer: "With bare metal deployments, you can lower your total cost of ownership by eliminating the need for expensive virtualization software and application licenses. Plus, enjoy a greater degree of control over your hardware and system setups that simply can't be matched by virtualized solutions. Discover the power and cost savings of bare metal deployments today."
                    }),
                JsxRuntime.jsx(Faq.make, {
                      question: "How Do I Complete a Bare Metal RFP Project?",
                      answerWithLink: Caml_option.some(JsxRuntime.jsxs("div", {
                                children: [
                                  "Get connected with top bare metal solution providers in just 2-3 minutes! Datacenters.com makes it easy - ",
                                  JsxRuntime.jsx("a", {
                                        children: "answer a few simple questions",
                                        className: css.answerWithLink,
                                        href: Routes_Project.BareMetal.$$new
                                      }),
                                  " and we'll submit a quote request on your behalf. Watch as leading providers compete for your business, allowing you to compare pricing and solutions side by side to find the perfect fit. No more hassle, no more stress - streamline your search today with Datacenters.com."
                                ]
                              }))
                    }),
                match[0] ? JsxRuntime.jsx(ScheduleModalServices.make, {
                        onClose: (function () {
                            setIsScheduleDemoModalOpen(function (param) {
                                  return false;
                                });
                          }),
                        title: "Schedule a Demo",
                        subtitle: "Submit the form to schedule a demo and learn more about bare metal solutions.",
                        buttonTitle: "Submit",
                        leadType: "BareMetal"
                      }) : null
              ],
              className: css.wrapper
            });
}

var make = BareMetalFaqs;

export {
  css ,
  make ,
}
/* css Not a pure module */
