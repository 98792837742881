// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Link from "../../../../../../styleguide/components/Link/Link.res.js";
import * as React from "react";
import * as Button from "../../../../../../styleguide/components/Button/Button.res.js";
import * as Popover from "../../../../../../styleguide/components/Popover/Popover.res.js";
import * as IconSelect from "../../../../../../styleguide/icons/IconSelect.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Routes_Project from "../../../../../../routes/common/Routes_Project.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ScheduleModalServices from "../../../../common/schedule-modal-services/ScheduleModalServices.res.js";
import * as BareMetalGetStartedButtonScss from "./BareMetalGetStartedButton.scss";

var css = BareMetalGetStartedButtonScss;

function BareMetalGetStartedButton(props) {
  var match = React.useState(function () {
        return "Closed";
      });
  var setScheduleDemoState = match[1];
  var tmp;
  tmp = match[0] === "Opened" ? JsxRuntime.jsx(ScheduleModalServices.make, {
          onClose: (function () {
              setScheduleDemoState(function (param) {
                    return "Closed";
                  });
            }),
          title: "Schedule a Demo",
          subtitle: "Submit the form to schedule a demo and learn more about bare metal solutions.",
          buttonTitle: "Submit",
          leadType: "BareMetal"
        }) : null;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Popover.make, {
                      children: [
                        JsxRuntime.jsx(Popover.Trigger.make, {
                              children: JsxRuntime.jsx(Button.make, {
                                    size: "LG",
                                    color: "Primary",
                                    className: css.ctaButton,
                                    children: "Get Started",
                                    iconSuffixComponent: Caml_option.some(JsxRuntime.jsx(IconSelect.make, {
                                              size: "LG",
                                              color: "White"
                                            }))
                                  })
                            }),
                        JsxRuntime.jsxs(Popover.Body.make, {
                              position: {
                                TAG: "Below",
                                _0: "RightEdge"
                              },
                              className: css.popoverBody,
                              children: [
                                JsxRuntime.jsxs(Link.make, {
                                      href: Routes_Project.BareMetal.$$new,
                                      className: css.popoverBodyLink,
                                      children: [
                                        "Create a Project",
                                        JsxRuntime.jsx(IconSelect.make, {
                                              size: "MD",
                                              color: "LightestGray",
                                              className: css.popoverBodyLinkIcon
                                            })
                                      ]
                                    }),
                                JsxRuntime.jsxs("a", {
                                      children: [
                                        "Schedule a Demo",
                                        JsxRuntime.jsx(IconSelect.make, {
                                              size: "MD",
                                              color: "LightestGray",
                                              className: css.popoverBodyLinkIcon
                                            })
                                      ],
                                      className: css.popoverBodyLink,
                                      href: "#",
                                      onClick: (function (param) {
                                          setScheduleDemoState(function (param) {
                                                return "Opened";
                                              });
                                        })
                                    })
                              ]
                            })
                      ]
                    }),
                tmp
              ]
            });
}

var make = BareMetalGetStartedButton;

export {
  css ,
  make ,
}
/* css Not a pure module */
