// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Button from "../../../../../../../styleguide/components/Button/Button.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as JumbotronProviderScss from "./JumbotronProvider.scss";

var css = JumbotronProviderScss;

function JumbotronProvider(props) {
  var disabled = props.disabled;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("img", {
                            src: props.logo,
                            width: "160"
                          }),
                      className: css.image
                    }),
                JsxRuntime.jsx("div", {
                      children: props.text,
                      className: css.text
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Button.AsLink.make, {
                            href: props.href,
                            size: "SM",
                            color: "Teal",
                            visuallyDisabled: disabled !== undefined ? disabled : false,
                            className: css.buttonLink,
                            children: disabled !== undefined && disabled ? "Coming Soon" : "View Details"
                          }),
                      className: css.button
                    })
              ],
              className: css.wrapper
            });
}

var make = JumbotronProvider;

export {
  css ,
  make ,
}
/* css Not a pure module */
