// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../../../../styleguide/components/Heading/H1.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as JumbotronProviders from "./components/JumbotronProviders.res.js";
import * as JumbotronBareMetalIndexScss from "./JumbotronBareMetalIndex.scss";

var css = JumbotronBareMetalIndexScss;

function JumbotronBareMetalIndex(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(H1.make, {
                      className: css.heading,
                      children: "Bare Metal Cloud & IaaS Servers"
                    }),
                JsxRuntime.jsx("div", {
                      children: "Choose a Bare Metal Provier from a curated list of top providers that offer different ranges of high-performance dedicated servers.",
                      className: css.subheading
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(JumbotronProviders.make, {}),
                      className: css.providers
                    })
              ],
              className: css.wrapper
            });
}

var make = JumbotronBareMetalIndex;

export {
  css ,
  make ,
}
/* css Not a pure module */
