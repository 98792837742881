// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Routes_Services from "../../../../../../../routes/common/Routes_Services.res.js";
import * as HorizontalScroll from "../../../../../../../styleguide/components/HorizontalScroll/HorizontalScroll.res.js";
import * as JumbotronProvider from "./JumbotronProvider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as JumbotronProvidersScss from "./JumbotronProviders.scss";
import LogoEnzuPng from "Images/companies/logo-enzu.png";
import LogoEvocativeJpeg from "Images/companies/logo-evocative.jpeg";
import VerticalPng from "Images/companies/zenlayer/vertical.png";
import HivelocityTextPng from "Images/companies/hivelocity/hivelocity-text.png";

var css = JumbotronProvidersScss;

function JumbotronProviders(props) {
  return JsxRuntime.jsx(HorizontalScroll.make, {
              children: JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsxs("div", {
                          children: [
                            JsxRuntime.jsx(JumbotronProvider.make, {
                                  text: "Predictable, high-powered performance for even the most demanding workloads",
                                  href: Routes_Services.BareMetal.enzu,
                                  logo: LogoEnzuPng
                                }),
                            JsxRuntime.jsx(JumbotronProvider.make, {
                                  text: "Complete control with servers dedicated exclusively to your business",
                                  href: Routes_Services.BareMetal.zenlayer,
                                  logo: VerticalPng
                                }),
                            JsxRuntime.jsx(JumbotronProvider.make, {
                                  text: "Dedicated server hosting with predictable costs. Bare metal servers deployed instantly in 40+ global markets.",
                                  href: Routes_Services.BareMetal.hivelocity,
                                  logo: HivelocityTextPng
                                }),
                            JsxRuntime.jsx(JumbotronProvider.make, {
                                  text: "Deploy enterprise-grade servers across 11 global metros on-demand.",
                                  href: Routes_Services.BareMetal.evocative,
                                  logo: LogoEvocativeJpeg
                                })
                          ],
                          className: css.providers
                        }),
                    className: css.wrapper
                  }),
              className: css.horizontalScroll
            });
}

var make = JumbotronProviders;

export {
  css ,
  make ,
}
/* css Not a pure module */
