// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as BareMetalHighlight from "./components/BareMetalHighlight.res.js";
import * as BareMetalHighlightsScss from "./BareMetalHighlights.scss";
import IconRapidDeploymentSvg from "./assets/icon-rapid-deployment.svg";
import IconDedicatedServersSvg from "./assets/icon-dedicated-servers.svg";
import IconConnectedToCloudSvg from "./assets/icon-connected-to-cloud.svg";

var css = BareMetalHighlightsScss;

var iconConnected = IconConnectedToCloudSvg;

var iconDedicatedServers = IconDedicatedServersSvg;

var iconRapidDeployment = IconRapidDeploymentSvg;

function BareMetalHighlights(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(BareMetalHighlight.make, {
                      title: "Dedicated Servers",
                      description: "These servers are dedicated just to your business, which is fully customizable and offers better performane.",
                      svgIcon: iconDedicatedServers
                    }),
                JsxRuntime.jsx(BareMetalHighlight.make, {
                      title: "Connected to the Cloud",
                      description: "Our bare metal providers offer simple platforms to manage your servers, which can be connect to mulitple public clouds.",
                      svgIcon: iconConnected
                    }),
                JsxRuntime.jsx(BareMetalHighlight.make, {
                      title: "Rapid Deployment",
                      description: "Quickly build a global network of servers that reach your users anywhere.",
                      svgIcon: iconRapidDeployment
                    })
              ],
              className: css.highlights
            });
}

var make = BareMetalHighlights;

export {
  css ,
  iconConnected ,
  iconDedicatedServers ,
  iconRapidDeployment ,
  make ,
}
/* css Not a pure module */
