// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as FaqScss from "./Faq.scss";
import * as Cloudinary from "../../../libs/Cloudinary.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";

var css = FaqScss;

var downTick = Cloudinary.imageUrl("f_auto/odyueovkug5xyu6wviwo");

var upTick = Cloudinary.imageUrl("f_auto/f6piw9kzvl4hxdluxnbo");

function Faq(props) {
  var answerWithLink = props.answerWithLink;
  var answer = props.answer;
  var match = React.useState(function () {
        return false;
      });
  var setIsExpanded = match[1];
  var isExpanded = match[0];
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("a", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: props.question
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx("img", {
                                          src: isExpanded ? upTick : downTick
                                        }),
                                    className: css.icon
                                  })
                            ],
                            className: css.link,
                            onClick: (function (param) {
                                setIsExpanded(function (state) {
                                      return !state;
                                    });
                              })
                          }),
                      className: css.questionBlock
                    }),
                isExpanded ? JsxRuntime.jsx("div", {
                        children: answer !== undefined ? (
                            answerWithLink !== undefined ? null : answer
                          ) : (
                            answerWithLink !== undefined ? Caml_option.valFromOption(answerWithLink) : null
                          ),
                        className: css.answer
                      }) : null
              ],
              className: css.faqContainer
            });
}

var make = Faq;

export {
  css ,
  downTick ,
  upTick ,
  make ,
}
/* css Not a pure module */
