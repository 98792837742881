// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H3 from "../../../../../../../styleguide/components/Heading/H3.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as WhatIsBareMetalSectionScss from "./WhatIsBareMetalSection.scss";

var css = WhatIsBareMetalSectionScss;

function WhatIsBareMetalSection(props) {
  var directionClass;
  directionClass = props.direction === "Left" ? css.left : css.right;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("img", {
                            className: css.svgIllustration,
                            height: "240",
                            src: props.image
                          }),
                      className: css.image
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H3.make, {
                              children: props.title
                            }),
                        JsxRuntime.jsx("div", {
                              children: props.text,
                              className: css.text
                            })
                      ],
                      className: css.description
                    })
              ],
              className: Cx.cx([
                    css.section,
                    directionClass
                  ])
            });
}

var make = WhatIsBareMetalSection;

export {
  css ,
  make ,
}
/* css Not a pure module */
