// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Routes_BlogPost from "../../../../../routes/common/Routes_BlogPost.res.js";
import Format from "date-fns/format";
import * as JsxRuntime from "react/jsx-runtime";
import * as BlogPostsSidebarSectionScss from "../BlogPostsSidebarSection.scss";

var css = BlogPostsSidebarSectionScss;

function BlogPostsSidebarBlogPost(props) {
  var blogPost = props.blogPost;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("a", {
                            children: blogPost.title,
                            href: Routes_BlogPost.show(blogPost.slug)
                          }),
                      className: css.blogPostTitle
                    }),
                JsxRuntime.jsx("div", {
                      children: Format(blogPost.publishedAt, "d MMM yyyy"),
                      className: css.blogPostDate
                    })
              ]
            });
}

var make = BlogPostsSidebarBlogPost;

export {
  css ,
  make ,
}
/* css Not a pure module */
